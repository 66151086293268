import React from 'react';
import {PanelData, PanelProps} from '@grafana/data';
import {SimpleOptions} from 'types';
import {css, cx} from '@emotion/css';
import {useStyles2, useTheme2} from '@grafana/ui';
import {makeColorSteps} from "../lib/colors";

interface Props extends PanelProps<SimpleOptions> {
}

const getStyles = () => {
    return {
        wrapper: css`
            font-family: Open Sans;
            position: relative;
        `,
        svg: css`
            position: absolute;
            top: 0;
            left: 0;
        `,
        textBox: css`
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 10px;
        `,
    };
};

function* iter_month(year: number, month: number): Generator<string, void> {
    let dt = new Date(year, month, 1, 12);
    let end = new Date(year, month + 1, 1, 12);
    while (dt < end) {
        yield   dtIsoFormat(dt);
        dt = new Date(dt.setDate(dt.getDate() + 1));
    }
}

function dtIsoFormat(dt: Date): string {
    return dt.toISOString().split('T')[0]
}

function getColor(ranges: Array<Record<string, any>>, value: number | undefined, nanColor = '#fff'): string {
    let color = nanColor;
    if (!!value) {
        let range = ranges.find(range => {
            return range.thresh > value
        });
        if (!range) {
            color = ranges[ranges.length - 1].color
        } else {
            color = range.color
        }
    }
    return color;
}

function getWeekNumber(dt: Date) {
    let d = new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()));
    let dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7)
}

function createYearMatrix(options: SimpleOptions, values: Record<string, number | undefined>) {
    let yearMatrix: Array<Array<Array<Record<string, any>>>> | null[][][] = [];
    for (let month = 0; month < 12; month++) {
        let monthMatrix = new Array(6);
        for (let week = 0; week < 6; week++) {
            monthMatrix[week] = new Array(7).fill(null)
        }
        let startWeek = null;

        for (const dt of iter_month(options.year, month)) {
            let dtObj = new Date(dt);
            let day = dtObj.getUTCDay();
            if (options.mondayStart) {
                day = ((day || 7) - 1)
            }
            let x = day % 7;

            if (startWeek === null) {
                startWeek = getWeekNumber(dtObj)
            }
            let y = getWeekNumber(dtObj) - startWeek;
            if (y < 0) {//year overflow
                y = 5;
            }
            monthMatrix[y][x] = {dt, val: values[dt]}
        }
        yearMatrix[month] = monthMatrix;
    }
    return yearMatrix;
}

function makeRanges(max: number, options: SimpleOptions, colors: string[]) {
    const ranges: Array<Record<string, any>> = [];
    let stepIncr = Math.floor(max / options.colorSteps);
    for (let i = 0; i <= options.colorSteps; i++) {
        ranges.push({color: colors[i], thresh: stepIncr * i})
    }
    return ranges;
}

function extractValues(data: PanelData) {
    let value_series = data.series[0].fields.find(f => f.type === 'number')?.values;
    let values: Record<string, number | undefined> = {};
    let max = 0;
    let time_series = data.series[0].fields.find(f => f.type === 'time')
    time_series?.values.forEach((time, index) => {
        let dt = dtIsoFormat(new Date(time));
        // @ts-ignore
        let value = value_series[index] || 0
        if (value > max) {
            max = value;
        }
        // @ts-ignore
        values[dt] = value;
    });
    return {values, max};
}

export const SimplePanel: React.FC<Props> = ({options, data, width, height}) => {
    // @ts-ignore
    const theme = useTheme2();
    const styles = useStyles2(getStyles);
    // const max = 100;
    let {values, max} = extractValues(data);
    let colors = makeColorSteps(options.startColor, options.endColor, options.colorSteps)
    const ranges = makeRanges(max, options, colors);

    let margin = 5
    const daySize = 10;
    const dayMargin = 2;
    const dayTotalSize = daySize + dayMargin
    let monthW = dayTotalSize * 7
    let monthH = 111;

    // shape (12,6,7)
    let yearMatrix = createYearMatrix(options, values);


    //todo optimizar las llamadas para que no se construyan las matrices cada vez ue sea redimenciona

    return (
        <div
            className={cx(
                styles.wrapper,
                css`
                    width: ${width}px;
                    height: ${height}px;
                `
            )}
        >
            <svg className="heatmap-container" x="0" y="0" height="100%" width="100%">
                <svg className="graph" x="0" y="0" fill="transparent">
                    {
                        new Array(12).fill(1).map((_, month) =>
                            <svg key={month} width={monthW} height={monthH} x={(month) * (monthW + margin)} y="0"
                                 className="graph-domain">
                                <rect width={monthW} height={monthH} className="domain-background"></rect>
                                <svg x={0} y={0} className={'graph-subdomain-group'} fill={'#fff'}>
                                    {yearMatrix[month].flat().map((day, index) =>
                                        <g key={index}>
                                            <rect className=" hover_cursor" width={daySize} height={daySize}
                                                  x={(index % 7) * dayTotalSize}
                                                  y={Math.floor(index / 7) * dayTotalSize}
                                                  fill={day !== null ? getColor(ranges, day.val, options.nanColor) : "transparent"}>
                                            </rect>
                                            {day !== null ? <title>{day.dt} - {day.val}</title> : null}
                                        </g>
                                    )}
                                </svg>
                                <text className="graph-label" y={monthH - 15} x={monthW / 2} textAnchor="middle"
                                      fill={'#fff'}
                                      dominantBaseline="middle">{options.year.toString(10).slice(-2)}/{(month + 1).toString(10).padStart(2, '0')}
                                </text>
                            </svg>
                        )
                    }
                </svg>
                {options.showLegend ?
                    <svg x="0" y={monthH + 20} className="graph-legend" height="10" width={12 * ranges.length}>
                        <g transform="">
                            {ranges.map((range, index) =>
                                <g key={index}>
                                    <rect width="10" height="10" x={(12 * index).toString(10)}
                                          className="hover_cursor" fillOpacity={1} fill={range.color}
                                    >
                                    </rect>
                                    <title>{range.thresh}</title>
                                </g>
                            )}
                        </g>
                    </svg> : null
                }
            </svg>
        </div>
    );
};
