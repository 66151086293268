import {PanelPlugin} from '@grafana/data';
import {SimpleOptions} from './types';
import {SimplePanel} from './components/SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
    return builder
        .addBooleanSwitch({path: 'showLegend', name: 'Show Legend', defaultValue: true,})
        .addNumberInput({path: 'colorSteps', name: 'Color steps', defaultValue: 4})
        .addNumberInput({path: 'year', name: 'Year', defaultValue: new Date().getFullYear()})
        .addColorPicker({path: "startColor", name: "Start color", defaultValue: "#fff"})
        .addColorPicker({path: "endColor", name: "End color", defaultValue: "#f00"})
        .addColorPicker({path: "nanColor", name: "Nan/null color", defaultValue: "#fff"})
        .addBooleanSwitch({
            path: "mondayStart", name: "Start week on monday", defaultValue: true, showIf: (opt) => false,
        })
        ;
});
