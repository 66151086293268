interface Pixel {
    r: number,
    g: number,
    b: number
}

function rgbToHex(r: number, g: number, b: number) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function hexToPixel(hex: string): Pixel {
    let result;
    if (hex.length <= 4) {
        result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hex);
        return result ? {
            r: parseInt(`${result[1]}${result[1]}`, 16),
            g: parseInt(`${result[2]}${result[2]}`, 16),
            b: parseInt(`${result[3]}${result[3]}`, 16),
        } : {r: 0, g: 0, b: 0};
    } else {
        result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : {r: 0, g: 0, b: 0};
    }
}

export function makeColorSteps(startColor: string, endColor: string, steps: number) {
    const colors = [];

    colors.push(startColor);

    let startColorRgb = hexToPixel(startColor);
    let endColorRgb = hexToPixel(endColor);

    let rInc = Math.round((endColorRgb.r - startColorRgb.r) / (steps + 1));
    let gInc = Math.round((endColorRgb.g - startColorRgb.g) / (steps + 1));
    let bInc = Math.round((endColorRgb.b - startColorRgb.b) / (steps + 1));

    for (let i = 0; i < steps - 1; i++) {
        startColorRgb.r += rInc;
        startColorRgb.g += gInc;
        startColorRgb.b += bInc;

        colors.push(rgbToHex(startColorRgb.r, startColorRgb.g, startColorRgb.b));
    }
    colors.push(endColor);

    return colors;
}
